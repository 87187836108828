<template>
  <v-card flat class="pa-0 mt-1">
    <v-card-text class="pa-0">
      <h3 :key="val" v-if="projectEquipmentObj.isProjectEquipment" class="pa-1 pl-2">{{$t('message.projects.project')}}: <router-link :to="`/projects/${projectEquipmentObj.projectId}`">{{ printProjectName }}</router-link></h3>
    </v-card-text>
    <scroll-list v-if="renderScrollList" id="equipmentScrollList" :payload="scrollListPayload"></scroll-list>
  </v-card>
</template>
<script>
import { hostAppApi } from '../../plugins/axios_settings'
import PermissionsMixin from '@/mixins/permissions'
import { mapGetters } from 'vuex'
export default {
  mixins: [PermissionsMixin],
  data () {
    return {
      count: 1,
      MODULE: '',
      projectEquipmentObj: {
        isProjectEquipment: false,
        projectId: 0
      },
      listOfStatus: [this.$t('message.equipments.registered'), this.$t('message.equipments.process'), this.$t('message.equipments.closed')],
      scrollListPayload: {
        width: 65,
        loading: false,
        module: '',
        moduleTitle: 'message.layout.equipments',
        addBtnTo: '',
        showScanCode: true,
        editHandler: null,
        actionSize: '0', // for delete action
        list: [],
        totalCount: 0,
        itemHeaders: [
          'message.equipments.equipmentNumber',
          'message.common.name',
          // 'message.equipments.dateofPurchase',
          // 'message.equipments.plannedCalibration',
          'Status'
        ],
        items: [{
          value: 'equipment_number',
          class: 'pa-0 pa-1 text-justify',
          cols: '4',
          spanClass: 'body pl-2',
          isParagraph: true
        }, {
          value: 'name',
          class: 'pa-0 pa-1 text-justify',
          cols: '4',
          spanClass: 'body pl-2',
          isParagraph: true
        },
        // {
        //   value: 'dateof_purchase',
        //   class: 'pa-0 pa-1 text-justify',
        //   cols: '4',
        //   spanClass: 'body pl-2',
        //   isParagraph: true
        // },
        // {
        //   value: 'planned',
        //   class: 'pa-0 pa-1 text-justify',
        //   cols: '2',
        //   spanClass: 'body pl-2',
        //   isParagraph: true
        // },
        {
          value: '',
          cols: '4',
          class: 'pa-0',
          rowClass: 'mt-1',
          subItems: [{
            value: 'equipment_status',
            class: 'pa-0',
            cols: '12',
            spanClass: 'subtitle'
          }]
        }],
        hasDelete: false,
        page: -1,
        previousSearch: '',
        recordsPerPage: 15
      },
      renderScrollList: false,
      listMaxCallLoaded: false,
      val: false,
      printProjectName: ''
    }
  },
  computed: {
    ...mapGetters(['getHostRefApi', 'userId'])
  },
  components: {
    'scroll-list': () => import('@/components/VirtualScrollList.vue')
  },
  created () {
    this.MODULE = 'equipment'
    if (this.$route.name === 'project_equipment') {
      this.MODULE = 'projectequipment'
      this.projectEquipmentObj.isProjectEquipment = true
      this.val = new Date().valueOf()
      this.projectEquipmentObj.projectId = parseInt(this.$route.params.project_id)
      // this.getProject(parseInt(this.$route.params.project_id))
    }
    this.scrollListPayload.editHandler = (item) => this.$router.push(this.projectEquipmentObj.isProjectEquipment ? `/equipments/${item.id}?project_id=${this.projectEquipmentObj.projectId}` : `/equipments/${item.id}`)
    this.scrollListPayload.addBtnTo = this.projectEquipmentObj.isProjectEquipment ? `/equipments/add?project_id=${this.projectEquipmentObj.projectId}` : '/equipments/add'
    if (this.$store.state.common.isEquipmentExcluded) this.$router.push('/')
    this.$store.dispatch('getGeneralSettings')
  },
  mounted () {
    this.getListHandler()
    const text = this.$t('message.common.deleteSuccess')
    const userid = this.$cookie.get(process.env.VUE_APP_HOST_USER) ? this.$cookie.get(process.env.VUE_APP_HOST_USER) : 0
    this.$eventBus.$on('deleteItems', (data) => {
      if (data.module === this.MODULE) {
        const payload = { url: `equipment/${data.id}?user_id=${parseInt(userid)}`, isSingle: true, module: this.MODULE, fromHostApi: true }
        this.$store.commit('showDeleteDialog', payload)
      }
    })
    this.$eventBus.$on('deleteSuccess', (data) => {
      if (data === this.MODULE) {
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text })
        this.$store.commit('hideDialog')
        this.getListHandler()
      }
    })
    // Load list on page
    this.$eventBus.$on('loadList', (data) => {
      this.getListHandler(data)
    })
  },
  methods: {
    getListHandler (search) {
      // const pagination = paginationObj || this.pagination
      // this.payload.pagination.page = pagination.page
      // this.payload.loading = true
      // this.payload.module = this.MODULE
      // const search = pagination.search ? pagination.search : ''
      // const pageVal = pagination.page // search ? 1 : pagination.page
      // const model = {
      //   start: ((pageVal - 1) * this.pagination.itemsPerPage),
      //   length: this.pagination.itemsPerPage,
      //   search: search
      // }
      if (search && (search !== this.scrollListPayload.previousSearch)) {
        this.scrollListPayload = { ...this.scrollListPayload, ...{ list: [], page: -1, previousSearch: search, totalCount: 0 } }
        this.listMaxCallLoaded = false
      }
      if (!search && this.scrollListPayload.previousSearch) {
        this.scrollListPayload = { ...this.scrollListPayload, ...{ list: [], page: -1, previousSearch: '', totalCount: 0 } }
        this.listMaxCallLoaded = false
      }
      if (this.listMaxCallLoaded) return
      this.scrollListPayload.loading = true
      this.scrollListPayload.module = this.MODULE
      this.scrollListPayload.page++
      const model = {
        start: (this.scrollListPayload.page * this.scrollListPayload.recordsPerPage),
        length: this.scrollListPayload.recordsPerPage
      }
      let url = `${this.getHostRefApi}equipment?only_items=1&start=${model.start}&length=${model.length}`
      if (this.projectEquipmentObj.isProjectEquipment) url += `&project_id=${this.projectEquipmentObj.projectId}`
      if (!this.scrollListPayload.showScanCode && search) url += `&search=${search}`
      hostAppApi.get(url)
        .then((response) => {
          if (response.data) {
            const newActiveData = response.data?.data?.filter((res) => res?.equipment_status === 'Active')
            let { data, recordsFiltered, projectFullName } = { ...response.data, data: newActiveData, recordsFiltered: newActiveData.length }
            this.scrollListPayload.total = recordsFiltered
            const transformData = {
              date: { list: ['deadline'], from: 'YYYY-MM-DD', to: 'DD.MM.YYYY' }
            }
            data = this.$formatter.formatListModel(data, transformData)
            data = data.map(value => { return { ...value, status: this.listOfStatus[value.status - 1] } })
            this.scrollListPayload.list = [...this.scrollListPayload.list, ...data]
            if (data.length) this.scrollListPayload.totalCount = recordsFiltered
            if (this.scrollListPayload.totalCount === this.scrollListPayload.list.length) this.listMaxCallLoaded = true
            this.printProjectName = projectFullName
            /* this.equipmentsCloneList = this.$formatter.cloneVariable(response.data.data)
            this.setVisibleEquipments() */
          }
        })
        .finally(() => {
          this.scrollListPayload.loading = false
          if (this.scrollListPayload.page === 0) this.renderScrollList = true
        })
    }
  },
  beforeDestroy () {
    this.$eventBus.$off('deleteSuccess')
    this.$eventBus.$off('deleteItems')
    this.$eventBus.$off('loadList')
  }
}
</script>
